import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaUpload } from 'react-icons/fa';
import { Link } from "react-router-dom";

const StallLayout = () => {

    const [isUploadImage, setIsUploadImage] = useState(false)

    const updateImage = (image) => {

        console.log(image)

        const formData = new FormData();

        formData.append('photo', image)

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        axios.post("https://lions320capi.emedha.in/update-layout", formData, config)
            .then((response) => {
                alert(response.data);
                setIsUploadImage(false)
                layOutData()
            })
    }

    const [image, setImage] = useState([])

    const layOutData = () => {
        axios.get('https://lions320capi.emedha.in/stall-layout').then((res) => {
            setImage(res.data)
        })
    }

    useEffect(() => {
        layOutData()
    }, [])


    return (
        <div style={{ width: '100%' }} >
            <div style={{ width: '80%', margin: 'auto' }} >
                <Link to={`/carnival-menu-events`}><button className='btn btn-success shadow' style={{ borderRadius: 2, marginLeft: 4 }}><strong> Carnival Home</strong></button></Link>
                <br />
                <button onClick={() => setIsUploadImage(!isUploadImage)} style={{ width: '17rem', height: '2.4rem', borderRadius: '0.1rem', backgroundColor: 'white', color: 'gold', border: '1.2px solid gold', borderStyle: 'dashed', marginBottom: 10 }} ><FaUpload style={{ position: 'relative', top: '-0.1rem' }} /> Update Layout</button>
                {
                    isUploadImage && (
                        <div>
                            <Button
                                variant="contained"
                                component="label"
                                style={{ height: '100px', width: '17rem', backgroundColor: 'white', position: 'relative', border: '1.2px solid gold', boxShadow: 'none', borderStyle: 'dashed', marginBottom: 7 }}
                            >
                                <FaUpload style={{ fontSize: 23, zIndex: 20, color: 'gold' }} />
                                <input
                                    required
                                    type="file"
                                    hidden
                                    onChange={(e) => updateImage(e.target.files[0])}
                                />
                            </Button>
                        </div>
                    )
                }


                {
                    image.map((data) => {
                        return (
                            <img src={data.image} width='100%' />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default StallLayout