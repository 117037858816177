import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Pagination from '../pagination/Pagination'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const StallDetails = () => {

    const navigate = useNavigate()

    const [Event, setStall] = useState([])
    const [showPerPage, setShowPerPage] = useState(10)
    const [pagination, setPagination] = useState(
        {
            start: 0,
            end: showPerPage
        }
    )

    const onChangePagination = (start, end) => {
        setPagination({ start: start, end: end })
    }

    const StallData = async () => {
        const res = await axios.get(`https://lions320capi.emedha.in/stall-data`)
        setStall(res.data)
    }

    useEffect(() => {
        StallData()
    }, []);


    const deleteData = (id) => {
        if (window.confirm('Are you sure to delete this data...')) {
            axios.post(`https://lions320capi.emedha.in/stall-delete`, {
                id
            })
            alert('Data deleted successfully!...')
            setTimeout(() => StallData(), 100)
        }
    }

    const [stallSize, setStallSize] = useState()
    const [stallPrice, setStallPrice] = useState()
    const [stallDesc, setStallDesc] = useState()
    const [stallOrder, setStallOrder] = useState()
    const [stallNameData, setStallNameData] = useState()
    const [id, setId] = useState()

    const stallDeatailsAdd = () => {
        axios.post('https://lions320capi.emedha.in/stall-update', {
            stallDesc,
            stallPrice,
            stallSize,
            stallNameData,
            stallOrder,
            id
        }).then((res) => {
            if (res.data.status === 200) {
                alert('Stall details data updated successfully')
                StallData()
                setShow1(false)
            }
        })
    }

    const [show1, setShow1] = useState()

    return (
        <div>
            <button onClick={() => navigate('/stall-details-add')} style={{ width: '7rem', height: '2.4rem', backgroundColor: 'rgb(223, 174, 25)', borderRadius: '0.2rem', border: 'none', color: 'white', fontWeight: '500', fontSize: 15 }} >Add</button>
            <div style={{ marginTop: '1rem' }} className='table-container'>
                <table className="styled-table table table-bordered">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Stall Name</th>
                            <th>Stall Size</th>
                            <th>Stall Price</th>
                            <th>Stall Description</th>
                            <th>Stall Order</th>

                            <th>Upadate</th>
                            <th>Delete</th>
                        </tr>

                    </thead>
                    <tbody className='table-bordered'>
                        {
                            Event.slice(pagination.start, pagination.end).map((data, index) => {
                                return (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{data.stallName}</td>
                                        <td>{data.StallSize}</td>
                                        <td>{data.StallPrice}</td>
                                        <td>{data.StallDesc}</td>

                                        <td>{data.stallOrder}</td>
                                        <td className=''>
                                            <button className='btn btn-info' onClick={() => {
                                                setStallSize(data.StallSize)
                                                setStallPrice(data.StallPrice)
                                                setStallDesc(data.StallDesc)
                                                setStallOrder(data.stallOrder)
                                                setStallNameData(data.stallName)
                                                setId(data.id)
                                                setShow1(true)
                                            }}>Update</button>
                                        </td>
                                        <td className=''>
                                            <Link to='#' className='btn btn-danger' onClick={() => deleteData(data.id)}>Delete</Link>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <Pagination page={showPerPage} onChangePagination={onChangePagination} total={Event.length} />
            </div>
            <>
                <Modal show={show1} onHide={() => setShow1(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ color: 'green' }} >Update</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input value={stallNameData} style={{ height: '53px', width: '100%', border: '1px solid lightgray', outline: 'none', marginBottom: 24, borderRadius: '0.2rem', paddingLeft: 20 }} onChange={(e) => setStallNameData(e.target.value)} placeholder='Stall Name' />
                        <input value={stallSize} style={{ height: '53px', width: '100%', border: '1px solid lightgray', outline: 'none', marginBottom: 24, borderRadius: '0.2rem', paddingLeft: 20 }} onChange={(e) => setStallSize(e.target.value)} placeholder='Stall Size' />
                        <input value={stallPrice} type='text' style={{ height: '53px', width: '100%', border: '1px solid lightgray', outline: 'none', marginBottom: 24, borderRadius: '0.2rem', paddingLeft: 20 }} onChange={(e) => setStallPrice(e.target.value)} placeholder='Stall Price' />
                        <input value={stallDesc} style={{ height: '53px', width: '100%', border: '1px solid lightgray', outline: 'none', marginBottom: 24, borderRadius: '0.2rem', paddingLeft: 20 }} placeholder='Stall Description' onChange={(e) => setStallDesc(e.target.value)} />
                        <input value={stallOrder} style={{ height: '53px', width: '100%', border: '1px solid lightgray', outline: 'none', marginBottom: 24, borderRadius: '0.2rem', paddingLeft: 20 }} placeholder='Stall Order' onChange={(e) => setStallOrder(e.target.value)} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button style={{ height: '2.6rem', backgroundColor: 'white', color: 'green', borderRadius: '0.3rem', border: '1.6px solid green', fontWeight: '500', width: '6rem' }} onClick={() => stallDeatailsAdd()}>
                            Update
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        </div>
    )
}

export default StallDetails