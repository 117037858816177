import axios from 'axios'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

const StallDetailsAdd = () => {

    const [StallSize, setStallSize] = useState()
    const [stallPrice, setStallPrice] = useState()
    const [stallDesc, setStallDesc] = useState()
    const [stallOrder, setStallOrder] = useState()
    const [stallNameData, setStallNameData] = useState()

    const navigate = useNavigate()

    const stallAdd = () => {
        axios.post('https://lions320capi.emedha.in/stall-details-add', {
            stallDesc,
            stallPrice,
            stallSize: StallSize,
            stallOrder,
            stallNameData
        }).then((res) => {
            if (res.data.status === 200) {
                alert('Stall details successfully updated')
                setTimeout(() => navigate('/stall-details'), 200)
            }
        })
    }

    const [stallName, setStallName] = useState([])
    const dataItem = async () => {

        const res = await axios.post(`https://lions320capi.emedha.in/carnival-member-data`, {
            ismember: 'Stall'
        })
        setStallName(res.data)

    }

    useEffect(() => {
        dataItem()
    }, [])

    return (
        <div className='conatner add-project-container mt-3'>
            <label>Stall Name</label>
            <select type='text' name='name' placeholder='Stall Size' className='form-control' style={{ height: '2.7rem', borderRadius: '0.2rem' }} onChange={(e) => setStallNameData(e.target.value)} >
                <option selected value='' disabled >
                    Stall Name
                </option>
                {
                    stallName.map((data) => {
                        return (
                            <option>
                                {data.name}
                            </option>
                        )
                    })
                }
            </select>
            <br></br>
            <label>Stall Order</label>
            <input type='text' name='name' placeholder='Stall Order' className='form-control' style={{ height: '2.7rem', borderRadius: '0.2rem' }} onChange={(e) => setStallOrder(e.target.value)} />
            <br></br>
            <label>Stall Size</label>
            <input type='text' name='name' placeholder='Stall Size' className='form-control' style={{ height: '2.7rem', borderRadius: '0.2rem' }} onChange={(e) => setStallSize(e.target.value)} />
            <br></br>
            <label>Stall Price</label>
            <input type='text' name='eventstallPrice' placeholder='Stall Price' className='form-control' style={{ height: '2.7rem', borderRadius: '0.2rem' }} onChange={(e) => setStallPrice(e.target.value)} />
            <br></br>
            <label>Stall Desc</label>
            <input type='text' name='StallSize' className='form-control' style={{ height: '2.7rem', borderRadius: '0.2rem' }} placeholder='Stall Description' onChange={(e) => setStallDesc(e.target.value)} />
            <br></br>
            <br></br>
            <button style={{ width: '7rem', height: '2.3rem', borderRadius: '0.2rem', backgroundColor: 'red', color: 'white', border: 'none' }} onClick={stallAdd}>Submit</button>
            <br></br>
        </div>
    )
}

export default StallDetailsAdd