import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Pagination from '../pagination/Pagination'
import { Link } from 'react-router-dom'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

const Project = () => {

  const [project,setProject]=useState([])
  const[showPerPage,setShowPerPage]=useState(10)
  const [pagination,setPagination]=useState(
    {
      start:0,
      end:showPerPage
    }
  )
 
  const onChangePagination=(start,end)=>{
    setPagination({start:start,end:end})
  }

  const projectsData= async ()=>{
    const res=await axios.get(`https://lions320capi.emedha.in/project`)
    setProject(res.data)
  }

  useEffect(() => {
    projectsData()
  }, []);

  
  const deleteData=(id)=>{
    if(window.confirm('Are you sure to delete this data...')){
      axios.post(`https://lions320capi.emedha.in/projects/delete`,{
        id
      })
      alert('Data deleted successfully!...')
      setTimeout(()=>projectsData(),100)
    }
  }

  return (
   <div>
    <Link to='/add-project'> <button  className='btn btn-warning'>
          Add<PersonAddAltIcon></PersonAddAltIcon>
       </button></Link>
    <div style={{marginTop:'1rem'}} className='table-container'>
        
        <table className="styled-table table table-bordered">
         <thead>
           <tr>
             <th>S.No</th>
             <th>Project_Name</th>
             <th>Club_Name</th>
             <th>Contact</th>
             <th>Description</th>
             <th>Photo</th>
             <th>Update</th>
             <th>Delete</th>
           </tr>
           
         </thead>
         <tbody className='table-bordered'>
           {
             project.slice(pagination.start,pagination.end).map((data,index)=>{
               return(
                 <tr>
                   <td>{index+1}</td>
                   <td>{data.projectName}</td>
                   <td>{data.clubName}</td>
                   <td>{data.contact}</td>
                   <td>{data.projectDesc}</td>
                   <img src={`https://lions320capi.emedha.in/images/${data.photo}`} height='70px' width='70px' style={{marginTop:'-0.4rem'}}/>
                   <td className=''>
                     <Link  className='btn btn-info' to={`/project-update/${data.sno}`}>update</Link>
                   </td>
                   <td className=''>
                     <Link to='#' className='btn btn-danger' onClick={()=>deleteData(data.sno)}>delete</Link>
                   </td>
                 </tr>
               )
             })
           }
         </tbody>
        </table>
       <Pagination page={showPerPage} onChangePagination={onChangePagination} total={project.length}/>      
   </div>
   </div>
  )
}

export default Project