import React, { useState, useEffect } from 'react'
import Pagination from '../pagination/Pagination';
import axios from 'axios'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'


const CarnivalRegisterMember = () => {

    const { memberType } = useParams()

    const [utr, setUtr] = useState('')
    const [amount, setAmount] = useState('')
    const [bank, setBank] = useState('')
    const [id, setId] = useState('')
    const [regNum, setRegNum] = useState('')
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [passType, setPassType] = useState()
    const [mobile, setMobile] = useState()
    const [stallSize, setStallSize] = useState('')
    const [stallNumber, setStallNumber] = useState('')
    const [stallPrice, setStallPrice] = useState('')
    const [stallDesc, setStallDesc] = useState('')
    const [PI, setPI] = useState()
    const [emailWait, setEmailWait] = useState(false)
    const [show3, setShow3] = useState(false)


    const [item, setDataItem] = useState([])
    const [showPerPage, setShowPerPage] = useState(10)
    const [pagination, setPagination] = useState(
        {
            start: 0,
            end: showPerPage
        }
    )

    const onChangePagination = (start, end) => {
        setPagination({ start: start, end: end })
    }

    const dataItem = async () => {

        const res = await axios.post(`https://lions320capi.emedha.in/carnival-member-data`, {
            ismember: memberType
        })
        setDataItem(res.data)

    }

    useEffect(() => {
        dataItem()
    }, [])


    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (id, regNum, name, email, mobile, pass, DonorPassPrice) => {
        setAmount(DonorPassPrice)
        setRegNum(regNum)
        setShow1(true)
        setId(id)
        setName(name)
        setEmail(email)
        setMobile(mobile)
        setPassType(pass)
        const max1 = 96723453213
        const regNum1 = 'PI' + Math.floor(Math.random() * max1)
        setPI(regNum1)
    };

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => {
        setShow2(false)
        setBank('')
        setAmount('')
        setUtr('')
    };
    const handleShow2 = () => {
        setShow2(true)
    };

    const formValidation = () => {
        if (utr === '' || amount === '' || bank === '') {
            alert('All fields is required')
        } else {
            addRecipt()
        }
    }

    const formValidation1 = () => {
        if (stallSize === '' || stallPrice === '' || stallDesc === '' || stallNumber === '') {
            alert('All fields is required')
        } else {
            bookStall()
        }
    }

    const addRecipt = () => {
        const dateData = new Date()
        const date = dateData.getDate() + "/" + (dateData.getMonth() + 1) + '/' + dateData.getFullYear()
        axios.post('https://lions320capi.emedha.in/add-receipt', {
            bank, amount, utr, id, PI, date
        }).then((res) => {
            sendWhatsAppReceipt1(res.data)

        })
    }

    const handleShow3 = (id, regNum) => {
        setRegNum(regNum)
        setShow3(true)
        setId(id)
    }

    const [date4, setDate] = useState()

    const viewReceipt = (name, utr, bank, regNum, amount, mobile, email, passType, PI, date, id) => {
        setBank(bank)
        setUtr(utr)
        setAmount(amount)
        setRegNum(regNum)
        setName(name)
        setEmail(email)
        setMobile(mobile)
        setPassType(passType)
        setPI(PI)
        setId(id)
        handleShow2()
        setFontSize1(15)
        setFontSize2(13)
        setModalSize('md')
        setDate(date)
    }

    const printDocument1 = () => {
        setFontSize1(6)
        setFontSize2(4)
        setShow4(false)
        const input = document.getElementById('divToPrint1');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'JPEG', 0, 0);
                // pdf.output('dataurlnewwindow');
                pdf.save("download.pdf");
            })
            ;
    }

    const printDocument = () => {
        setFontSize1(6)
        setFontSize2(4)
        setShow2(false)
        const input = document.getElementById('divToPrint');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'JPEG', 0, 0);
                // pdf.output('dataurlnewwindow');
                pdf.save("download.pdf");
            })
            ;
    }

    const emailReceipt = () => {
        setEmailWait(true)
        axios.post('https://lions320capi.emedha.in/reciept-send', {
            email,
            regNum,
            mobile,
            utr,
            amount,
            bank,
            name,
            passType,
            date: date4,
            PI
        }).then((res) => {
            if (res.data.status === 200) {
                setEmailWait(false)
                alert('Email sent successfully')
                setShow2(false)
            }
        })
    }

    const bookStall = () => {
        axios.post('https://lions320capi.emedha.in/book-stall', {
            stallSize, stallNumber, stallPrice, stallDesc, id
        }).then((res) => {
            alert(res.data)
            dataItem()
            setShow3(false)
        })
    }


    const [fontSize1, setFontSize1] = useState(15)
    const [fontSize2, setFontSize2] = useState(13)
    const [modalSize, setModalSize] = useState('md')

    useEffect(() => {
        setFontSize1(15)
        setFontSize2(13)
    }, [])


    const date = new Date()

    const genPdf = () => {
        setModalSize('sm')
        setFontSize1(9.6)
        setFontSize2(7.5)
        setTimeout(() => {
            printDocument()
        }, 100)
    }

    const genPdf1 = () => {
        setModalSize('sm')
        setFontSize1(9.6)
        setFontSize2(7.5)
        setTimeout(() => {
            printDocument1()
        }, 100)
    }


    const [show4, setShow4] = useState(false)
    const stallDetails = (name, stallSize, stallNumber, stallDesc, stallPrice, regNum, mobile, email, id) => {
        setName(name)
        setStallSize(stallSize)
        setStallNumber(stallNumber)
        setStallDesc(stallDesc)
        setStallPrice(stallPrice)
        setRegNum(regNum)
        setMobile(mobile)
        setEmail(email)
        setShow4(true)
        setFontSize1(15)
        setFontSize2(13)
        setModalSize('md')
        setId(id)
    }

    const sendWhatsAppReceipt = (pdf) => {

        const link = `https://lions320capi.emedha.in/download-pdf/${pdf}`

        axios.post(`https://lions320capi.emedha.in/send-receipt-whatsapp`, {
            mobile,
            email,
            name,
            link
        }).then((res) => {
            alert(res.data)
            setShow4(false)
            setShow2(false)
        })

    }

    const sendWhatsAppReceipt1 = (alertMsg) => {

        axios.post(`https://lions320capi.emedha.in/send-create-receipt-whatsapp`, {
            name, mobile, regNum, passType, PI
        }).then((res) => {
            alert(alertMsg)
            setShow1(false)
            dataItem()
        })
    }


    const sendWhatsAppDownloadReceipt = () => {
        axios.post(`https://lions320capi.emedha.in/gen-pdf/${id}`, {
            PI
        }).then((res) => {
            if (res.data.status === 200) {
                sendWhatsAppReceipt(res.data.pdf)
            }
        })
    }

    return (

        <>
            <div style={{ marginTop: '1rem' }} className='table-container'>
                <Link to={`/carnival-menu-events`}><button className='btn btn-success shadow' style={{ borderRadius: 2, marginLeft: 4 }}><strong> Carnival Home</strong></button></Link>
                <table className="styled-table table table-bordered">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Mobile</th>
                            <th>Email</th>
                            <th>Pass Type</th>
                            <th>Pass Number</th>
                            <th>Pass Price (INR)</th>
                            <th>Club Name</th>
                            <th>Pay Receipt</th>
                        </tr>

                    </thead>
                    <tbody className='table-bordered'>
                        {

                            item.slice(pagination.start, pagination.end).map((data, index) => {
                                return (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{data.name}</td>
                                        <td>{data.mobile}</td>
                                        <td>{data.email}</td>
                                        <td>{data.Category}</td>
                                        <td>{data.PassNumber}</td>
                                        <td>{data.DonorPassPrice}</td>
                                        <td>{data.clubName}</td>

                                        {
                                            memberType === 'Stall' && (
                                                <td>
                                                    {
                                                        data.isStallBook === 0 ? (
                                                            <td className=''>
                                                                <button to='#' style={{ height: '2.3rem', borderRadius: '0.2rem', border: '1px solid gold', backgroundColor: 'white', color: 'gold', width: '7rem' }} onClick={() => {
                                                                    handleShow3(data.id, data.regNum)
                                                                }}>Book Stall</button>
                                                            </td>
                                                        ) : (
                                                            (data.isPayment === 1) ? (
                                                                <td className=''>
                                                                    <button to='#' style={{ height: '2.3rem', borderRadius: '0.2rem', border: '1px solid green', backgroundColor: 'white', color: 'green', width: '7rem', marginRight: 2 }} onClick={() => viewReceipt(data.name, data.UTRDetails, data.BankDetails, data.regNum, data.amount, data.mobile, data.email, data.Category, data.PI, data.date, data.id)}>View Receipt</button>
                                                                    <button to='#' style={{ height: '2.3rem', borderRadius: '0.2rem', border: '1px solid green', backgroundColor: 'white', color: 'green', width: '7rem' }} onClick={() => stallDetails(data.name, data.stallSize, data.stallNumber, data.stallDesc, data.stallPrice, data.regNum, data.mobile, data.email, data.id)} >Stall Details</button>
                                                                </td>
                                                            ) : (
                                                                <td className=''>
                                                                    <button to='#' style={{ height: '2.3rem', borderRadius: '0.2rem', border: '1px solid gold', backgroundColor: 'white', color: 'gold', width: '7rem', marginRight: 2, fontSize: 13 }} onClick={() => handleShow1(data.id, data.regNum, data.name, data.email, data.mobile, data.Category, data.DonorPassPrice)} >Create Receipt</button>
                                                                    <button to='#' style={{ height: '2.3rem', borderRadius: '0.2rem', border: '1px solid green', backgroundColor: 'white', color: 'green', width: '7rem', fontSize: 13 }} onClick={() => stallDetails(data.name, data.stallSize, data.stallNumber, data.stallDesc, data.stallPrice, data.regNum, data.mobile, data.email)} >Stall Details</button>
                                                                </td>
                                                            )
                                                        )
                                                    }
                                                </td>
                                            )
                                        }
                                        {
                                            !(memberType === 'Stall') && (
                                                <div>
                                                    {
                                                        !(data.isPayment === 1) && (
                                                            <td className=''>
                                                                <button to='#' style={{ height: '2.3rem', borderRadius: '0.2rem', border: '1px solid gold', backgroundColor: 'white', color: 'gold', width: '7rem', fontSize: 13 }} onClick={() => handleShow1(data.id, data.regNum, data.name, data.email, data.mobile, data.Category, data.DonorPassPrice)}>Create Receipt</button>
                                                            </td>
                                                        )
                                                    }

                                                    {
                                                        (data.isPayment === 1) && (
                                                            <td className=''>
                                                                <button to='#' style={{ height: '2.3rem', borderRadius: '0.2rem', border: '1px solid green', backgroundColor: 'white', color: 'green', width: '7rem' }} onClick={() => viewReceipt(data.name, data.UTRDetails, data.BankDetails, data.regNum, data.amount, data.mobile, data.email, data.Category, data.PI, data.date, data.id)}>View Receipt</button>
                                                            </td>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <Pagination page={showPerPage} onChangePagination={onChangePagination} total={item.length} />

                <>
                    <Modal show={show1} style={{ paddingTop: 10 }} onHide={handleClose1}>

                        <center><Modal.Title style={{ color: 'rgb(244, 202, 78)', fontSize: 19, marginTop: 10 }} ><img width='47px' src={require('../img/lions_club.png')} /><br />Lions Carnival <br />District 320C International Association of Lions Clubs</Modal.Title></center>

                        <Modal.Body>
                            <Typography style={{ fontSize: 18, color: 'grren', marginBottom: 5 }} >Reg. NO {regNum}</Typography>
                            <Typography style={{ fontSize: 13, color: 'grren', marginBottom: 5 }} >Date: {date.getDate() + "/" + (date.getMonth() + 1) + '/' + date.getFullYear()}</Typography>
                            <Typography style={{ marginBottom: 10 }} >Payment Id: {PI}</Typography>
                            <input onChange={(e) => setUtr(e.target.value)} style={{ height: '53px', width: '100%', border: '1px solid lightgray', outline: 'none', marginBottom: 24, borderRadius: '0.2rem', paddingLeft: 20 }} placeholder='Enter UTR/Cheque details*' />
                            <input onChange={(e) => setBank(e.target.value)} style={{ height: '53px', width: '100%', border: '1px solid lightgray', outline: 'none', marginBottom: 24, borderRadius: '0.2rem', paddingLeft: 20 }} placeholder='Enter Bank details*' />
                            <input onChange={(e) => setAmount(e.target.value)} style={{ height: '53px', width: '100%', border: '1px solid lightgray', outline: 'none', marginBottom: 24, borderRadius: '0.2rem', paddingLeft: 20 }} placeholder='Enter amount*' />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button style={{ height: '2.6rem', backgroundColor: 'white', color: 'green', borderRadius: '0.3rem', border: '1.6px solid green', fontWeight: '500', width: '6rem' }} onClick={formValidation}>
                                Submit
                            </Button>
                            <Button style={{ height: '2.6rem', backgroundColor: 'white', color: 'red', borderRadius: '0.3rem', border: '1.6px solid red', fontWeight: '500', width: '6rem' }} onClick={() => setShow1(false)}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>




                <>
                    <Modal show={show3} style={{ paddingTop: 10 }} onHide={() => setShow3(false)}>

                        <center><Modal.Title style={{ color: 'rgb(244, 202, 78)', fontSize: 19, marginTop: 10 }} ><img width='47px' src={require('../img/lions_club.png')} /><br />Lions Carnival <br />District 320C International Association of Lions Clubs</Modal.Title></center>

                        <Modal.Body>
                            <Typography style={{ fontSize: 18, color: 'grren', marginBottom: 5 }} >Reg. NO {regNum}</Typography>
                            <input onChange={(e) => setStallSize(e.target.value)} style={{ height: '53px', width: '100%', border: '1px solid lightgray', outline: 'none', marginBottom: 24, borderRadius: '0.2rem', paddingLeft: 20 }} placeholder='Stall Size' />
                            <input onChange={(e) => setStallNumber(e.target.value)} style={{ height: '53px', width: '100%', border: '1px solid lightgray', outline: 'none', marginBottom: 24, borderRadius: '0.2rem', paddingLeft: 20 }} placeholder='Stall Number' />
                            <input onChange={(e) => setStallPrice(e.target.value)} style={{ height: '53px', width: '100%', border: '1px solid lightgray', outline: 'none', marginBottom: 24, borderRadius: '0.2rem', paddingLeft: 20 }} placeholder='Stall Price' />
                            <textarea rows={4} onChange={(e) => setStallDesc(e.target.value)} style={{ width: '100%', border: '1px solid lightgray', outline: 'none', marginBottom: 24, borderRadius: '0.2rem', paddingLeft: 20 }} placeholder='Stall Description' />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button style={{ height: '2.6rem', backgroundColor: 'white', color: 'green', borderRadius: '0.3rem', border: '1.6px solid green', fontWeight: '500', width: '8rem' }} onClick={formValidation1}>
                                Book Now
                            </Button>
                            <Button style={{ height: '2.6rem', backgroundColor: 'white', color: 'red', borderRadius: '0.3rem', border: '1.6px solid red', fontWeight: '500', width: '6rem' }} onClick={() => setShow3(false)}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>



                <>
                    <Modal show={show1} style={{ paddingTop: 10 }} onHide={handleClose1}>

                        <center><Modal.Title style={{ color: 'rgb(244, 202, 78)', fontSize: 19, marginTop: 10 }} ><img width='47px' src={require('../img/lions_club.png')} /><br />Lions Carnival <br />District 320C International Association of Lions Clubs</Modal.Title></center>

                        <Modal.Body>
                            <Typography style={{ fontSize: 18, color: 'grren', marginBottom: 5 }} >Reg. NO {regNum}</Typography>
                            <Typography style={{ fontSize: 13, color: 'grren', marginBottom: 5 }} >Date: {date.getDate() + "/" + (date.getMonth() + 1) + '/' + date.getFullYear()}</Typography>
                            <Typography style={{ marginBottom: 10 }} >Payment Id: {PI}</Typography>
                            <input onChange={(e) => setUtr(e.target.value)} style={{ height: '53px', width: '100%', border: '1px solid lightgray', outline: 'none', marginBottom: 24, borderRadius: '0.2rem', paddingLeft: 20 }} placeholder='Enter UTR/Cheque details*' />
                            <input onChange={(e) => setBank(e.target.value)} style={{ height: '53px', width: '100%', border: '1px solid lightgray', outline: 'none', marginBottom: 24, borderRadius: '0.2rem', paddingLeft: 20 }} placeholder='Enter Bank details*' />
                            <input value={amount + ' ' + 'Amount'} readOnly style={{ height: '53px', width: '100%', border: '1px solid lightgray', outline: 'none', marginBottom: 24, borderRadius: '0.2rem', paddingLeft: 20 }} placeholder='Enter amount*' />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button style={{ height: '2.6rem', backgroundColor: 'white', color: 'green', borderRadius: '0.3rem', border: '1.6px solid green', fontWeight: '500', width: '6rem' }} onClick={formValidation}>
                                Submit
                            </Button>
                            <Button style={{ height: '2.6rem', backgroundColor: 'white', color: 'red', borderRadius: '0.3rem', border: '1.6px solid red', fontWeight: '500', width: '6rem' }} onClick={() => setShow1(false)}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>

                <>
                    <Modal size={modalSize} show={show2} onHide={handleClose2}>
                        <Modal.Body id="divToPrint" className="mt4" style={{ borderTop: '1px solid gold', }} >
                            <div className='container-fluid' >
                                <div className='row' >
                                    <center><Modal.Title style={{ color: 'rgb(244, 202, 78)', fontSize: 19, marginTop: 10, marginBottom: 30 }} ><img width='47px' src={require('../img/lions_club.png')} /><br />Lions Carnival <br />District 320C International Association of Lions Clubs</Modal.Title></center>
                                    <Typography className='col-6' style={{ fontSize: fontSize1, marginBottom: 20, fontWeight: 'bold', textDecoration: 'underline' }}>Donation Receipt</Typography>
                                    <Typography className='col-12' style={{ fontSize: fontSize2, marginBottom: 20, borderBottom: '1px solid lightgray', fontWeight: 'bold' }}>Reg No: {regNum}</Typography>
                                    <Typography className='col-12' style={{ fontSize: fontSize2, marginBottom: 20, borderBottom: '1px solid lightgray' }}>Payment Id: {PI}</Typography>
                                    <Typography className='col-6' style={{ fontSize: fontSize2, marginBottom: 20, borderBottom: '1px solid lightgray' }}>Name: {name}</Typography>
                                    <Typography className='col-6' style={{ fontSize: fontSize2, marginBottom: 20, borderBottom: '1px solid lightgray' }}>Mobile: {mobile}</Typography>
                                    <Typography className='col-12' style={{ fontSize: fontSize2, marginBottom: 20, borderBottom: '1px solid lightgray' }}>Email: {email}</Typography>
                                    <Typography className='col-6' style={{ fontSize: fontSize2, marginBottom: 20, borderBottom: '1px solid lightgray' }}>UTR Details: {utr}</Typography>
                                    <Typography className='col-6' style={{ fontSize: fontSize2, marginBottom: 20, borderBottom: '1px solid lightgray' }}>Bank Details: {bank}</Typography>
                                    <Typography className='col-6' style={{ fontSize: fontSize2, marginBottom: 20, borderBottom: '1px solid lightgray' }}>Pass Type: {passType}</Typography>
                                    <Typography className='col-6' style={{ fontSize: fontSize2, marginBottom: 20, borderBottom: '1px solid lightgray' }}>Amount: {amount}</Typography>
                                    <Typography className='col-12' style={{ fontSize: fontSize2, marginBottom: 20, borderBottom: '1px solid lightgray' }}>Date: {date4}</Typography>
                                </div>
                            </div>
                        </Modal.Body>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', paddingLeft: 4, paddingRight: 4 }} >
                            <Button style={{ height: '2.2rem', backgroundColor: 'white', color: 'rgb(222, 186, 43)', borderRadius: '0.3rem', border: '1.6px solid rgb(222, 186, 43)', fontWeight: '500', width: '6rem' }} onClick={genPdf}>
                                <p style={{ position: 'relative', top: '-0.123rem' }} >PDF</p>
                            </Button>

                            <Button style={{ height: '2.2rem', backgroundColor: 'white', color: 'green', borderRadius: '0.3rem', border: '1.6px solid green', fontWeight: '500', width: '6rem' }} onClick={sendWhatsAppDownloadReceipt}>
                                <p style={{ position: 'relative', top: '-0.123rem' }} >WhatsApp</p>
                            </Button>
                            {
                                !emailWait && (
                                    <Button style={{ height: '2.2rem', backgroundColor: 'white', color: 'rgb(102, 33, 25)', borderRadius: '0.3rem', border: '1.6px solid rgb(102, 33, 25)', fontWeight: '500', width: '6rem' }} onClick={() => emailReceipt()}>
                                        <p style={{ position: 'relative', top: '-0.123rem' }} >Email</p>
                                    </Button>
                                )
                            }
                            {
                                emailWait && (
                                    <Button style={{ height: '2.2rem', backgroundColor: 'white', color: 'gray', borderRadius: '0.3rem', border: '1.6px solid gray', fontWeight: '500', width: '6rem' }}>
                                        <p style={{ position: 'relative', top: '-0.123rem' }} >Sending..</p>
                                    </Button>
                                )
                            }
                            <Button style={{ height: '2.2rem', backgroundColor: 'white', color: 'red', borderRadius: '0.3rem', border: '1.6px solid red', fontWeight: '500', width: '6rem' }} onClick={() => setShow2(false)}>
                                <p style={{ position: 'relative', top: '-0.123rem' }} >Cancel</p>
                            </Button>
                        </div>
                    </Modal>
                </>

                <>
                    <Modal size={modalSize} show={show4} onHide={() => {
                        setShow4(false)
                        setStallSize('')
                        setStallNumber('')
                        setStallDesc('')
                        setStallPrice('')
                    }}>
                        <Modal.Body id="divToPrint1" className="mt4" style={{ borderTop: '1px solid gold', }} >
                            <div className='container-fluid' >
                                <div className='row' >
                                    <center><Modal.Title style={{ color: 'rgb(244, 202, 78)', fontSize: 19, marginTop: 10, marginBottom: 30 }} ><img width='47px' src={require('../img/lions_club.png')} /><br />Lions Carnival <br />District 320C International Association of Lions Clubs</Modal.Title></center>
                                    <Typography className='col-6' style={{ fontSize: fontSize1, marginBottom: 20, fontWeight: 'bold', textDecoration: 'underline' }}>Stall Details</Typography>
                                    <Typography className='col-12' style={{ fontSize: fontSize2, marginBottom: 20, borderBottom: '1px solid lightgray', fontWeight: 'bold' }}>Reg No: {regNum}</Typography>
                                    <Typography className='col-6' style={{ fontSize: fontSize2, marginBottom: 20, borderBottom: '1px solid lightgray' }}>Name: {name}</Typography>
                                    <Typography className='col-6' style={{ fontSize: fontSize2, marginBottom: 20, borderBottom: '1px solid lightgray' }}>Mobile: {mobile}</Typography>
                                    <Typography className='col-12' style={{ fontSize: fontSize2, marginBottom: 20, borderBottom: '1px solid lightgray' }}>Email: {email}</Typography>
                                    <Typography className='col-6' style={{ fontSize: fontSize2, marginBottom: 20, borderBottom: '1px solid lightgray' }}>Stall Size: {stallSize}</Typography>
                                    <Typography className='col-6' style={{ fontSize: fontSize2, marginBottom: 20, borderBottom: '1px solid lightgray' }}>Stall Number: {stallNumber}</Typography>
                                    <Typography className='col-6' style={{ fontSize: fontSize2, marginBottom: 20, borderBottom: '1px solid lightgray' }}>Stall Price: {stallPrice}</Typography>
                                    <Typography className='col-6' style={{ fontSize: fontSize2, marginBottom: 20, borderBottom: '1px solid lightgray' }}>Stall Description: {stallDesc}</Typography>
                                </div>
                            </div>
                        </Modal.Body>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', paddingLeft: 4, paddingRight: 4 }} >
                            <Button style={{ height: '2.2rem', backgroundColor: 'white', color: 'rgb(222, 186, 43)', borderRadius: '0.3rem', border: '1.6px solid rgb(222, 186, 43)', fontWeight: '500', width: '6rem' }} onClick={genPdf1}>
                                <p style={{ position: 'relative', top: '-0.123rem' }} >PDF</p>
                            </Button>

                            <Button style={{ height: '2.2rem', backgroundColor: 'white', color: 'green', borderRadius: '0.3rem', border: '1.6px solid green', fontWeight: '500', width: '6rem' }} onClick={sendWhatsAppReceipt}>
                                <p style={{ position: 'relative', top: '-0.123rem' }} >WhatsApp</p>
                            </Button>
                            {
                                !emailWait && (
                                    <Button style={{ height: '2.2rem', backgroundColor: 'white', color: 'rgb(102, 33, 25)', borderRadius: '0.3rem', border: '1.6px solid rgb(102, 33, 25)', fontWeight: '500', width: '6rem' }} onClick={() => emailReceipt()}>
                                        <p style={{ position: 'relative', top: '-0.123rem' }} >Email</p>
                                    </Button>
                                )
                            }
                            {
                                emailWait && (
                                    <Button style={{ height: '2.2rem', backgroundColor: 'white', color: 'gray', borderRadius: '0.3rem', border: '1.6px solid gray', fontWeight: '500', width: '6rem' }}>
                                        <p style={{ position: 'relative', top: '-0.123rem' }} >Sending..</p>
                                    </Button>
                                )
                            }
                            <Button style={{ height: '2.2rem', backgroundColor: 'white', color: 'red', borderRadius: '0.3rem', border: '1.6px solid red', fontWeight: '500', width: '6rem' }} onClick={() => setShow4(false)}>
                                <p style={{ position: 'relative', top: '-0.123rem' }} >Cancel</p>
                            </Button>
                        </div>
                    </Modal>
                </>
            </div>
        </>
    )
}

export default CarnivalRegisterMember