import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Pagination from '../pagination/Pagination'
import { Link  } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { Container } from '@mui/system'
import {FaSearch} from 'react-icons/fa'


const International = () => {

  const [International,setInternational]=useState([])
  const[showPerPage]=useState(5)
  const [pagination,setPagination]=useState(
    {
      start:0,
      end:showPerPage
    }
  )
 
  const onChangePagination=(start,end)=>{
    setPagination({start:start,end:end})
  }

  const InternationalData= async ()=>{
    const res=await axios.get(`https://lions320capi.emedha.in/international/get`)
    setInternational(res.data)
  }


  useEffect(()=>{
    InternationalData()
  },[])
  
  const [myMessage,setMessage]=useState('')
  
  const [InternationalName1,setInternationalName]=useState('')
  const [desc1,setDesc]=useState('')
  const [email1,setEmail]=useState('')
  const [position1,setPosition]=useState('')
  const [photo, setPhoto] = useState('')
 

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);  

  const [search,setSearch]=useState()

  const InternationalPost=(e)=>{

    e.preventDefault();
    const formData = new FormData();
  
    formData.append('email',email1)
    formData.append('name',InternationalName1)
    formData.append('desc',desc1)
    formData.append('position',position1)
    formData.append('photo',photo)
  
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
  
    axios.post("https://lions320capi.emedha.in/International/post",formData,config)
    .then((response) => {
      alert(response.data);
      setTimeout(()=>setShow(false),200)
      InternationalData()
        }).catch((error) => {
      });
  
  }

  


  const deleteData=(id)=>{
    if(window.confirm('Are you sure to delete this data...')){
      axios.post(`https://lions320capi.emedha.in/International/delete`,{
        id
      })
      alert('Data deleted successfully!...')
      setTimeout(()=>InternationalData(),100)
    }
  }



  const searchHandler=(e)=>{
     setSearch(e.target.value)

     axios.post('https://lions320capi.emedha.in/search',{
      search
     }).then((resp)=>{
      setInternational(resp.data)
     })
  }


  return (

    <>
    <Container fluid><h5 className='text-center text-danger'>{myMessage}</h5></Container>
    <Button variant="warning" onClick={handleShow}>
        Add<PersonAddAltIcon></PersonAddAltIcon>
    </Button>
    <Modal show={show} onHide={handleClose} centered>
       <Modal.Header closeButton>
         <Modal.Title>Add</Modal.Title>
      </Modal.Header>
       <Modal.Body>
         <form onSubmit={InternationalPost}>
           
           <input type='text'  className='form-control' name="name" placeholder='Enter Name' required onChange={(e)=>setInternationalName(e.target.value)}/>
           <br/>
           <input type='text' className='form-control' name="email" placeholder='Email'   onChange={(e)=>setEmail(e.target.value)}/>
           <br/>
           <input type='text'   className='form-control' name="position" placeholder='Position'   onChange={(e)=>setPosition(e.target.value)}/>
           <br/>
           <input type='file'   className='form-control' name="position" placeholder='Position'   onChange={(e)=>setPhoto(e.target.files[0])}/>
           <br/>
           <textarea rows={5} type='text'   className='form-control' name="position" placeholder='Description'   onChange={(e)=>setDesc(e.target.value)}/>
           <br/>
           <button type='submit' className='btn btn-danger form-control'>Submit</button>
           <br/>
           <button type='reset' className='btn btn-secondary form-control'>Reset</button>
         </form>
       </Modal.Body>
       <Modal.Footer>
         <Button variant="secondary" onClick={handleClose}>
           Close
         </Button>
       </Modal.Footer>
  </Modal>
  <FaSearch className='searchIcon'></FaSearch><input type='search' className='ms-4 search-input-field' placeholder='Search..' onChange={searchHandler}/>
  <div style={{marginTop:'1rem'}} className='table-container'>
       <table className="styled-table table  table-striped table-responsive table-bordered">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Name</th>
            <th>Email</th>
            <th>Photo</th>
            <th>Position</th>
            <th>Description</th>
            <th>Update</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody className='table-bordered'>
          {
            International.slice(pagination.start,pagination.end).map((data,index)=>{
              return(
                 
                    <tr>
                        <td>{data.id}</td>
                        <td>{data.name}</td>
                        <td>{data.email}</td>
                        <td><img src = {data.photo} width='80px' /></td>
                        <td>{data.position}</td> 
                        <td>{data.description}</td>
                        <td className=''>
                             <Link to={`/internationalUpdate/${data.id}`}><button  className='btn btn-info' >update</button></Link>
                        </td>
                        <td className=''>
                            <Link to='#' className='btn btn-danger' onClick={()=>deleteData(data.id)}>delete</Link>
                        </td>
                    </tr>
                 
              )
            })
          }
        </tbody>
       </table>
      <Pagination page={showPerPage} onChangePagination={onChangePagination} total={International.length}/>      
  </div>
</>
  )
}

export default International