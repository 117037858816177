import axios from 'axios'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

const UpdateCarnivalRegister = () => {

    const date1 = new Date()

    const [venue, setVenue] = useState('Imperial Gardens')
    const [date, setDate] = useState("03-12-2023")
    const [time, setTime] = useState()
    const [schedule, setSchedule] = useState()
    const navigate = useNavigate()


    const eventUpdate = () => {
        axios.post('https://lions320capi.emedha.in/carnival-update-register', {
            schedule,
            time,
            date,
            venue,
        }).then((res) => {
            if (res.data.status === 200) {
                alert('Carnival event updated successfully')
                setTimeout(() => navigate('/update-carnival-data'), 200)
            }
        })
    }

    return (
        <div className='conatner add-project-container mt-3'>
            <label>Venue</label>
            <input readOnly value={venue} type='text' name='name' placeholder='Venue' className='form-control' style={{ height: '2.7rem', borderRadius: '0.2rem' }} onChange={(e) => setVenue(e.target.value)} />
            <br></br>
            <label>Date</label>
            <input value={date} name='eventdate' type='date' placeholder='Date' className='form-control' style={{ height: '2.7rem', borderRadius: '0.2rem' }} onChange={(e) => setDate(e.target.value)} />
            <br></br>
            <label>Time</label>
            <input type='time' name='venue' className='form-control' style={{ height: '2.7rem', borderRadius: '0.2rem' }} placeholder='Time' onChange={(e) => setTime(e.target.value)} />
            <br></br>
            <label>Event</label>
            <input type='text' name='venue' className='form-control' style={{ height: '2.7rem', borderRadius: '0.2rem' }} placeholder='Event' onChange={(e) => setSchedule(e.target.value)} />
            <br></br>
            <br></br>
            <button style={{ width: '7rem', height: '2.3rem', borderRadius: '0.2rem', backgroundColor: 'red', color: 'white', border: 'none' }} onClick={eventUpdate}>Submit</button>
            <br></br>
        </div>
    )
}

export default UpdateCarnivalRegister