import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Pagination from '../pagination/Pagination'
import { Link } from 'react-router-dom'


const Report = () => {

  const [report,setReport]=useState([])
  const[showPerPage,setShowPerPage]=useState(10)
  const [pagination,setPagination]=useState(
    {
      start:0,
      end:showPerPage
    }
  )
 
  const onChangePagination=(start,end)=>{
    setPagination({start:start,end:end})
  }

  const reportData= async ()=>{

    const res=await axios.get(`https://lions320capi.emedha.in/report`)
    setReport(res.data)

  }

  useEffect(() => {
    reportData()
  }, []);


  
  const deleteData=(id)=>{
    if(window.confirm('Are you sure to delete this data...')){
      axios.delete(`https://lions320capi.emedha.in/deleteReport/${id}`)
      alert('Data deleted successfully!...')
      setTimeout(()=>reportData(),100)
    }
  }

  return (
  <div style={{marginTop:'1rem'}} className='table-container'>
       <table className="styled-table table table-bordered">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Membership_Number</th>
            <th>Month</th>
            <th>Activity</th>
            <th>Sub_Level_Activity</th>
            <th>Mini_Level_Activity</th>
            <th>No_Of_Benificiarries</th>
             <th>Points</th>
             <th>Photo</th>
             <th>Update</th>
             <th>Delete</th>
          </tr>
          
        </thead>
        <tbody>
          {
            report.slice(pagination.start,pagination.end).map((data,index)=>{
              return(
                <tr>
                  <td>{index+1}</td>
                  <td>{data.membershipNumber}</td>
                  <td>{data.repsubmonth}</td>
                  <td>{data.mactivity}</td>
                  <td>{data.subActivity}</td>
                  <td>{data.miniActivity}</td>
                  <td>{data.NoOfBenificiaries}</td>
                  <td>{data.points}</td>
                  <td>{data.photo}</td>
                  
                  <td className=''>
                    <Link to='#' className='btn btn-info'>update</Link>
                  </td>
                  <td className=''>
                    <Link to='#' className='btn btn-danger' onClick={()=>deleteData(data.membershipNo)}>delete</Link>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
       </table>
      <Pagination page={showPerPage} onChangePagination={onChangePagination} total={report.length}/>      
  </div>
  )
}

export default Report