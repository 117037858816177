import React, { useState, useEffect } from 'react'
import Pagination from '../pagination/Pagination';
import axios from 'axios'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

// Import Material Icons



const NotificationMessage = () => {

    const [notification, setNotifcation] = useState([])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [ad, setAd] = useState([])
    const [showPerPage, setShowPerPage] = useState(10)
    const [pagination, setPagination] = useState(
        {
            start: 0,
            end: showPerPage
        }
    )

    const onChangePagination = (start, end) => {
        setPagination({ start: start, end: end })
    }

    const advertisement_get = async () => {
        const res = await axios.post(`https://lions320capi.emedha.in/notifications/get`)
        setNotifcation(res.data)
    }

    const deleteNotify = (id) => {
        if (window.confirm('Are you sure to delete this data...')) {
            axios.post(`https://lions320capi.emedha.in/notification/delete`, {
                id
            })
            alert('Data deleted successfully!...')
            advertisement_get()
        }
    }

    useEffect(() => {
        advertisement_get()
    }, [])

    return (

        <>
            <div style={{ marginTop: '1rem' }} className='table-container'>
                <table className="styled-table table table-bordered">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Message</th>
                            <th>Title</th>
                            <th>Date</th>
                        </tr>

                    </thead>
                    <tbody className='table-bordered'>
                        {

                            notification.slice(pagination.start, pagination.end).map((data, index) => {
                                return (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{data.message}</td>
                                        <td>{data.title}</td>
                                        <td>{data.dtime}</td>
                                        <td className=''>
                                            <Link to='#' className='btn btn-danger' onClick={() => deleteNotify(data.sno)}>delete</Link>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <Pagination page={showPerPage} onChangePagination={onChangePagination} total={ad.length} />
            </div>
        </>
    )
}

export default NotificationMessage