import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const DGdairyInput = () => {

  const [sDate,setSdate] = useState()
  const [eDate,setEdate] = useState()
  const [program,setProgram] = useState()
  const [description,setDescription] = useState()


  const navigate = useNavigate()
  const postDiary = ()=>{
    axios.post('https://lions320capi.emedha.in/diary/post',{
      sDate,
      eDate,
      program,
      description
    }).then((res)=>{
      alert(res.data)
      setTimeout(()=>navigate('/DG-diary'),200)
    })
  }

  return (
    <div className='conatner add-project-container'>
      <label>Start Date</label>
        <input type='date' className='form-control'  onChange={(e)=>setSdate(e.target.value)}/>
        <br></br>
        <label>End Date</label>
        <input type='date' className='form-control' onChange={(e)=>setEdate(e.target.value)}/>
        <br></br>
        <select id="pet-select" className='form-control' onChange={(e)=>setProgram(e.target.value)}>
          <option value="">------Select Program------</option>
          <option value="dog">Club Official Visit</option>
          <option value="cat">District Program</option>
          <option value="hamster">Multiple Program</option>
          <option value="parrot">INTERNATIONAL PROGRAM</option>
          <option value="spider">Other</option>
        </select>
        <label>Description</label>
        <textarea type='text' rows={6} className='form-control' placeholder='Event Name' onChange={(e)=>setDescription(e.target.value)}/>
        <br></br>
      <button className='btn btn-danger mb-2' onClick={postDiary}>Submit</button>
      <br></br>
    </div>
  )
}

export default DGdairyInput