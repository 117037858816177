import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const InternationalUpdate = () => {

  const { id } = useParams()

  const navigate = useNavigate()

  const [photo1, setPhoto1] = useState('')


  const [International, setInternational] = useState([])
  const [showPerPage] = useState(5)
  const [pagination, setPagination] = useState(
    {
      start: 0,
      end: showPerPage
    }
  )

  const onChangePagination = (start, end) => {
    setPagination({ start: start, end: end })
  }

  const InternationalData = async () => {
    const res = await axios.get(`https://lions320capi.emedha.in/international/get`)
    setInternational(res.data)
  }


  useEffect(() => {
    InternationalData()
  }, [])


  const inInternational = {
    email: '',
    position: '',
    name: '',
    description: '',
  }

  const [InternationalUpdate, setInternationalUpdate] = useState(inInternational)
  const { email, position, name, description } = InternationalUpdate





  const handleShow = () => {
    axios.post('https://lions320capi.emedha.in/International/update/get', {
      id
    }).then((res) => {
      setInternationalUpdate({ ...res.data[0] })
    })
  }

  useEffect(() => {
    handleShow()
  }, [])


  const InternationalUpdateData = (e) => {


    e.preventDefault()

    const formData = new FormData();

    formData.append('email', email)
    formData.append('name', name)
    formData.append('desc', description)
    formData.append('position', position)
    formData.append('photo', photo1)
    formData.append('id', id)


    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    axios.post("https://lions320capi.emedha.in/International/update/post", formData, config)
      .then((response) => {
        alert(response.data);
        InternationalData()
        navigate('/international')
      })
  }


  const onInputChange = (e) => {
    setInternationalUpdate({ ...InternationalUpdate, [e.target.name]: e.target.value })
  }


  return (
    <div>
      <form onSubmit={InternationalUpdateData}>
        <input type='text' name="name" value={name} className='form-control' placeholder='Enter Name' required onChange={onInputChange} />
        <br />
        <input type='text' name="email" value={email} className='form-control' placeholder='Email' onChange={onInputChange} />
        <br />
        <input type='file' className='form-control' name="photo" placeholder='Position' onChange={(e)=> setPhoto1(e.target.files[0])} />
        <br />
        <input type='text' name="position" value={position} className='form-control' placeholder='Position' onChange={onInputChange} />
        <br />
        <input type='text' value={description} className='form-control' placeholder='Description' name='description' onChange={onInputChange} />
        <br />
        <button type='submit' className='btn btn-danger form-control'>Update</button>
        <br />
        <button type='reset' className='btn btn-secondary form-control'>Reset</button>
      </form>
    </div>
  )
}

export default InternationalUpdate