import React, { useState } from 'react'
import './App.css'
import { BrowserRouter } from 'react-router-dom'
import { useEffect } from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min'
import Navigation from './component/adminDashboard/Navbar'
import AdminLogin from './component/AdminLogin/AdminLogin'


const App = () => {

  const [condition, setCondition] = useState(null)
  const getSession = sessionStorage.getItem('email')

  useEffect(() => {
    setCondition(getSession)
  })

  return (
    <div>
      <BrowserRouter>
        {
          condition === null ? <AdminLogin condition={setCondition}></AdminLogin> : <Navigation condition={setCondition}></Navigation>
        }
      </BrowserRouter>
    </div>
  )
}

export default App