import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

const UpdateClub = () => {

  const { clubName, clubId } = useParams();

  const navigate = useNavigate()


  const [memberMaster, setMemberMaster] = useState([])
  const [president, setPresident] = useState('')
  const [secretory, setSecretory] = useState('')
  const [treasurer, setTreasurer] = useState('')
  const [clubSponsoredBy, setClubSponsoredBy] = useState('')
  const [chartedOn, setChartedOn] = useState('')
  const [extn, setExtn] = useState('')
  const [meets, setMeets] = useState('')
  const [place, setPlace] = useState('')
  const [region, setRegion] = useState('')
  const [charter, setCharter] = useState('')
  const [zone, setZone] = useState('')
  const [totalMembers, setTotalMembers] = useState('')
  const [category, setCategory] = useState('')



  const updateClub = () => {
    axios.post(`https://lions320capi.emedha.in/club-master/update`, {
      category,
      chartedOn,
      charter,
      clubName,
      clubSponsoredBy,
      extn,
      meets,
      place,
      president,
      region,
      secretory,
      totalMembers,
      treasurer,
      zone,
      clubName,
      clubId
    }).then((resp) => {
      alert(resp.data)
      navigate('/view-club')
    })
  }

  const members = () => {
    axios.post(`https://lions320capi.emedha.in/member-master/club`, {
      clubName
    }).then((resp) => setMemberMaster(resp.data))
  }

  const [zc, setzc] = useState([])
  const [rc, setrc] = useState([])

  const zChair = () => {
    axios.post(`https://lions320capi.emedha.in/cabinet-rezion/chair`, {
      clubName
    }).then((resp) => setrc(resp.data))
  }

  const rChair = () => {
    axios.post(`https://lions320capi.emedha.in/cabinet-zone/chair`, {
      clubName
    }).then((resp) => setzc(resp.data))
  }

  const clubdata = () => {
   
    axios.post('https://lions320capi.emedha.in/club_data/get', {
      clubName
    }).then((res) => {
      setPresident(res.data.president)
      setCategory(res.data.category)
      setMeets(res.data.meetsOn)
      setChartedOn(res.data.chartedOn)
      setZone(res.data.zoneChairperson)
      setRegion(res.data.regionChairperson)
      setTreasurer(res.data.treasurer)
      setTotalMembers(res.data.totalMembers)
      setSecretory(res.data.secretory)
      setCharter(res.data.charterPresident)
      setExtn(res.data.extn)
      setClubSponsoredBy(res.data.clubSponsoredBy)
    })
  }


  useEffect(() => {
    members()
    zChair()
    rChair()
    clubdata()
  }, []);


  return (
    <div className='col-lg-6 col-md-8 col-12 mx-auto shadow-0'>
      <input type='text' name='clubName' value={clubId} className='form-control' placeholder='Club Name'  />
      <br />
      <input type='text' name='clubName' value={clubName} className='form-control' placeholder='Club Name' />
      <br />
      <select onChange={(e) => setPresident(e.target.value)} type='text' name='secretory' className='form-control' placeholder='Scretory'   >
        <option>Select President</option>
        {
          memberMaster.map((data) => {
            if (data.secretory === null) {
              return
            }
            return (
              <option>
                {data.membershipName}
              </option>
            )

          })
        }
      </select>
      <br />
      <select onChange={(e) => setSecretory(e.target.value)} type='text' name='secretory' className='form-control' placeholder='Scretory'   >
        <option>Select Secretary</option>
        {
          memberMaster.map((data) => {
            if (data.secretory === null) {
              return
            }
            return (
              <option>
                {data.membershipName}
              </option>
            )

          })
        }
      </select>
      <br />
      <select onChange={(e) => setTreasurer(e.target.value)} type='text' name='secretory' className='form-control' placeholder='Scretory'   >
        <option>Select Treasurer</option>
        {
          memberMaster.map((data) => {
            if (data.secretory === null) {
              return
            }
            return (
              <option>
                {data.membershipName}
              </option>
            )

          })
        }
      </select>
      <br />
      <input value={clubSponsoredBy || ''} onChange={(e) => setClubSponsoredBy(e.target.value)} type='text' name='clubSponsoredBy' className='form-control' placeholder='Club Sponsored By' />
      <br />
      <input value={chartedOn || ''} onChange={(e) => setChartedOn(e.target.value)} type='date' name='charterOn' className='form-control' placeholder='Charted On' />
      <br />
      <input value={charter || ''} type='text' onChange={(e) => setCharter(e.target.value)} name='charterPresident' className='form-control' placeholder='Charter President' />
      <br />
      <input value={extn || ''} type='text' onChange={(e) => setExtn(e.target.value)} name='extnChairperson' className='form-control' placeholder='Extn Chairperson' />
      <br />
      <input value={meets || ''} type='text' name='meetOn' onChange={(e) => setMeets(e.target.value)} className='form-control' placeholder='Meets On' />
      <br />
      <input type='text' value={place || ''} onChange={(e) => setPlace(e.target.value)} name='place' className='form-control' placeholder='Place' />
      <br />
      <select type='text' onChange={(e) => setZone(e.target.value)} name='secretory' className='form-control' placeholder='Scretory'   >
        <option>Select Zone Chairperson</option>
        {
          zc.map((data) => {
            return (
              <option>
                {data.membershipName}
              </option>
            )

          })
        }
      </select>
      <br />
      <select onChange={(e) => setRegion(e.target.value)} type='text' name='secretory' className='form-control' placeholder='Scretory'   >
        <option>Select Region Chairperson</option>
        {
          rc.map((data) => {
            return (
              <option>
                {data.membershipName}
              </option>
            )

          })
        }
      </select>
      <br />
      <input value={totalMembers || ''} onChange={(e) => setTotalMembers(e.target.value)} type='text' name='totalMembers' className='form-control' placeholder='Total Members' />
      <br />
      <input type='text' value={category || ''} onChange={(e) => setCategory(e.target.value)} name='category' className='form-control' placeholder='Category' />
      <br />
      <button type='submit' className='btn btn-danger form-control' onClick={() => updateClub()}>Update</button>
      <br />

    </div>
  )
}

export default UpdateClub