import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Pagination from '../pagination/Pagination'
import { Link } from 'react-router-dom'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

const DgDiary = () => {

  const [DgDiary,setDgDiary]=useState([])
  const[showPerPage,setShowPerPage]=useState(10)
  const [pagination,setPagination]=useState(
    {
      start:0,
      end:showPerPage
    }
  )
 
  const onChangePagination=(start,end)=>{
    setPagination({start:start,end:end})
  }

  const DgDiarysData= async ()=>{
    const res=await axios.get(`https://lions320capi.emedha.in/diary/get`)
    setDgDiary(res.data)
  }

  useEffect(() => {
    DgDiarysData()
  }, []);

  
  const deleteData=(id)=>{
    if(window.confirm('Are you sure to delete this data...')){
      axios.post(`https://lions320capi.emedha.in/diary/delete`,{
        id
      })
      alert('Data deleted successfully!...')
      setTimeout(()=>DgDiarysData(),100)
    }
  }

  return (
   <div>
    <Link to='/add-DG-dairy'> <button  className='btn btn-warning'>
          Add<PersonAddAltIcon></PersonAddAltIcon>
       </button></Link>
    <div style={{marginTop:'1rem'}} className='table-container'>
        
        <table className="styled-table table table-bordered">
         <thead>
           <tr>
             <th>S.No</th>
             <th>Start Date</th>
             <th>End Date</th>
             <th>Program</th>
             <th>Description</th>
             <th>Update</th>
             <th>Delete</th>
           </tr>
           
         </thead>
         <tbody className='table-bordered'>
           {
             DgDiary.slice(pagination.start,pagination.end).map((data,index)=>{
               return(
                 <tr>
                   <td>{index+1}</td>
                   <td>{data.startDate}</td>
                   <td>{data.endDate}</td>
                   <td>{data.program}</td>
                   <td>{data.description}</td>
                   <td className=''>
                     <Link  className='btn btn-info' to={`/Dg-Diary-update/${data.id}`}>update</Link>
                   </td>
                   <td className=''>
                     <Link to='#' className='btn btn-danger' onClick={()=>deleteData(data.id)}>delete</Link>
                   </td>
                 </tr>
               )
             })
           }
         </tbody>
        </table>
       <Pagination page={showPerPage} onChangePagination={onChangePagination} total={DgDiary.length}/>      
   </div>
   </div>
  )
}

export default DgDiary