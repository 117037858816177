import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Pagination from '../pagination/Pagination'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const CarnivalUpadateData = () => {

    const navigate = useNavigate()

    const [Event, setEvent] = useState([])
    const [showPerPage, setShowPerPage] = useState(10)
    const [pagination, setPagination] = useState(
        {
            start: 0,
            end: showPerPage
        }
    )

    const onChangePagination = (start, end) => {
        setPagination({ start: start, end: end })
    }

    const EventsData = async () => {
        const res = await axios.get(`https://lions320capi.emedha.in/carnival-update-data`)
        setEvent(res.data)
    }

    useEffect(() => {
        EventsData()
    }, []);


    const deleteData = (id) => {
        if (window.confirm('Are you sure to delete this data...')) {
            axios.post(`https://lions320capi.emedha.in/carnival-update-delete`, {
                id
            })
            alert('Data deleted successfully!...')
            setTimeout(() => EventsData(), 100)
        }
    }

    const date1 = new Date()
    const newDate = date1.getDate() + '/' + (date1.getMonth() + 1) + '/' + date1.getFullYear()

    const [venue, setVenue] = useState('Imperial Gardens')
    const [date, setDate] = useState(newDate)
    const [time, setTime] = useState()
    const [schedule, setSchedule] = useState()
    const [id, setId] = useState()

    const eventUpdate = () => {
        axios.post('https://lions320capi.emedha.in/carnival-event-update', {
            schedule,
            time,
            date,
            venue,
            id
        }).then((res) => {
            if (res.data.status === 200) {
                alert('Carnival event data updated successfully')
                EventsData()
                setShow1(false)
            }
        })
    }

    const [show1, setShow1] = useState()

    return (
        <div>
           
            <Link to={`/carnival-menu-events`}><button className='btn btn-success shadow' style={{ borderRadius: 2, marginRight: 4 }}><strong> Carnival Home</strong></button></Link>
            <button className='btn btn-success shadow' onClick={() => navigate('/update-carnival-register')} style={{ width: '7rem', height: '2.4rem', backgroundColor: 'rgb(223, 174, 25)', borderRadius: '0.2rem', border: 'none', color: 'white', fontWeight: '500', fontSize: 15 }} >Add</button>

            <div style={{ marginTop: '1rem' }} className='table-container'>

                <table className="styled-table table table-bordered">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Venue</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Event</th>
                            <th>Update</th>
                            <th>Delete</th>
                        </tr>

                    </thead>
                    <tbody className='table-bordered'>
                        {
                            Event.slice(pagination.start, pagination.end).map((data, index) => {
                                return (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{data.Venue}</td>
                                        <td>{data.date}</td>
                                        <td>{data.time}</td>
                                        <td>{data.schedule}</td>
                                        <td className=''>
                                            <button className='btn btn-info' onClick={() => {
                                                setTime(data.time)
                                                setSchedule(data.schedule)
                                                setId(data.id)
                                                setShow1(true)
                                            }}>Update</button>
                                        </td>
                                        <td className=''>
                                            <Link to='#' className='btn btn-danger' onClick={() => deleteData(data.id)}>Delete</Link>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <Pagination page={showPerPage} onChangePagination={onChangePagination} total={Event.length} />
            </div>
            <>
                <Modal show={show1} onHide={() => setShow1(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ color: 'green' }} >Update</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input readOnly value={venue} style={{ height: '53px', width: '100%', border: '1px solid lightgray', outline: 'none', marginBottom: 24, borderRadius: '0.2rem', paddingLeft: 20 }} onChange={(e) => setVenue(e.target.value)} placeholder='Venue' />
                        <input readOnly value={date} type='text' style={{ height: '53px', width: '100%', border: '1px solid lightgray', outline: 'none', marginBottom: 24, borderRadius: '0.2rem', paddingLeft: 20 }} onChange={(e) => setDate(e.target.value)} placeholder='Date' />
                        <input value={time} type='time' style={{ height: '53px', width: '100%', border: '1px solid lightgray', outline: 'none', marginBottom: 24, borderRadius: '0.2rem', paddingLeft: 20 }} placeholder='Time' onChange={(e) => setTime(e.target.value)} />
                        <input value={schedule} style={{ height: '53px', width: '100%', border: '1px solid lightgray', outline: 'none', marginBottom: 24, borderRadius: '0.2rem', paddingLeft: 20 }} placeholder='Schedule' onChange={(e) => setSchedule(e.target.value)} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button style={{ height: '2.6rem', backgroundColor: 'white', color: 'green', borderRadius: '0.3rem', border: '1.6px solid green', fontWeight: '500', width: '6rem' }} onClick={() => eventUpdate()}>
                            Update
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        </div>
    )
}

export default CarnivalUpadateData