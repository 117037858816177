import React, { useState } from 'react'
import { useEffect } from 'react'

const Pagination = ({page,onChangePagination,total}) => {


    const [counter,setCounter]=useState(1)

    const onClickButton=(type)=>{
        if(type==='prev'){
            if(counter===1){
                setCounter(1)
            }else{
                setCounter(counter-1)
            }
        }
        
        if(type==='next'){
            if(Math.ceil(total/page)===counter)
            {
                setCounter(counter)
            }
            else{
                setCounter(counter+1)
            }
        }
        if(type==='last'){
            setCounter(Math.ceil(total/page))
        }
        if(type==='first'){
            setCounter(1)
        }

    }
    useEffect(()=>{
        const end=counter*page
        const start=end-page
        onChangePagination(start,end)
    },[counter])

  return (
    <div className='container'>
        <div className='d-flex justify-content-between'>
            <button type='button' className='pgntionBtn' onClick={()=> onClickButton('first')}>First-Page</button>
             <div>
                <button type='button' className=' ms-5 pgntionBtn' onClick={()=> onClickButton('prev')}>Previous</button>
                <button type='button' className='pgntionBtn'>{counter}</button>
                <button type='button' className='pgntionBtn' onClick={()=>onClickButton('next')}>Next.......</button>
             </div>
            <button type='button' className=' ms-5' onClick={()=>onClickButton('last')}>Last-Page</button>
        </div>
    </div>
  )
}

export default Pagination