import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

const Update = () => {

  const { memberId } = useParams();

  const initialState = {

    address: '',

    birthAnniversary: '',

    bloodGroup: '',

    cabinetDesignation: '',

    clubs: '',

    description: '',

    designation: '',

    email: '',

    joiningDate: '',

    landlineNumber: '',

    marriageAnniversary: '',

    membershipName: '',

    membershipNo: '',

    mobileNumber: '',

    photo: '',

    profession: '',

    profile: '',

    spouseName: '',

  }

  const [state, setState] = useState(initialState)

  const {

    address,

    birthAnniversary,

    bloodGroup,

    cabinetDesignation,

    clubs,

    description,

    designation,

    email,

    joiningDate,

    landlineNumber,

    marriageAnniversary,

    membershipName,

    membershipNo,

    mobile,

    photo,

    profession,

    profile,

    spouseName,


  } = state


  const navigate = useNavigate()

  const updateData = (e) => {

    e.preventDefault();

    axios.put(`https://lions320capi.emedha.in/memberMasterUpdateData`, {
      address,

      birthAnniversary,

      bloodGroup,

      cabinetDesignation,

      clubs,

      description,

      designation,

      email,

      joiningDate,

      landlineNumber,

      marriageAnniversary,

      membershipName,

      membershipNo,

      mobile,

      photo,

      profession,

      profile,

      spouseName,

      memberId,
    }).then((resp) => {
      alert(resp.data)
      navigate('/view-member')
    })
  }





  const forUpdateData = () => {

    axios.get(`https://lions320capi.emedha.in/memberMasteData/${memberId}`).then((resp) => setState({ ...resp.data[0] }))

  }

  useEffect(() => {
    forUpdateData()
  }, [memberId]);

  const handelInputField = (e) => {

    setState({ ...state, [e.target.name]: e.target.value })

  }


  const updateMemberImage = (image) => {
    const formData = new FormData()
    formData.append('image', image)
    formData.append('mobile', mobile)
    axios.post('https://lions320capi.emedha.in/update-member-photo', formData).then((res) => {
      console.log(res.data)
      forUpdateData()
    })
  }

  return (
    <div>
      <form onSubmit={updateData}>
        <input type='number' name='membershipNo' value={membershipNo} className='form-control' placeholder='Membership Number' required onChange={handelInputField} />
        <br />
        <input type='text' name='membershipName' value={membershipName} className='form-control' placeholder='Membership Name' required onChange={handelInputField} />
        <br />
        <input type='text' name='clubs' value={clubs} className='form-control' placeholder='Club Name' required onChange={handelInputField} />
        <br />
        <input type='text' name='designation' value={designation} className='form-control' placeholder='Designation' onChange={handelInputField} />
        <br />
        <input type='text' name='profession' value={profession} className='form-control' placeholder='Profession' onChange={handelInputField} />
        <br />
        <input type='date' name='birthAnniversary' className='form-control' value={birthAnniversary} placeholder='Birth Anniversary' onChange={handelInputField} />
        <br />
        <input type='date' name='marriageAnniversary' value={marriageAnniversary} className='form-control' placeholder='Marriage Anniversary' onChange={handelInputField} />
        <br />
        <input type='text' name='bloodGroup' value={bloodGroup} className='form-control' placeholder='Blood Group' onChange={handelInputField} />
        <br />
        <input type='text' name='email' value={email} className='form-control' placeholder='Email Id' onChange={handelInputField} />
        <br />
        <input type='text' name='mobile' value={mobile} className='form-control' placeholder='Mobile number' onChange={handelInputField} />
        <br />
        <input type='text' name='spouseName' value={spouseName} className='form-control' placeholder='Spouse Name' onChange={handelInputField} />
        <br />
        <input type='text' name='address' value={address} className='form-control' placeholder='Address' onChange={handelInputField} />
        <br />
        <div className='card p-1' >
          <img src={`https://lions320capi.emedha.in/images/${photo}`} height='80px' width='80px' style={{ borderRadius: 40, border: '1px solid gray' }} />
          <br />
          <input type='file' onChange={(e) => updateMemberImage(e.target.files[0])} />
        </div>
        <button type='submit' className='btn btn-danger form-control'>Submit</button>
        <br />
        <br />
        <button type='reset' className='btn btn-secondary form-control'>Reset</button>
      </form>
    </div>
  )
}

export default Update