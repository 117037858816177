import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Pagination from '../pagination/Pagination'
import { Link  } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { Container } from '@mui/system'
import {FaSearch} from 'react-icons/fa'


const Multiple = () => {

  const [Multiple,setMultiple]=useState([])
  const [MultipleClubName,setMultipleClubName]=useState([])
  const[showPerPage]=useState(5)
  const [pagination,setPagination]=useState(
    {
      start:0,
      end:showPerPage
    }
  )
 
  const onChangePagination=(start,end)=>{
    setPagination({start:start,end:end})
  }

  const MultipleData= async ()=>{
    const res=await axios.get(`https://lions320capi.emedha.in/multiple/get`)
    console.log(res.data)
  }


  useEffect(()=>{
    MultipleData()
  },[])
  
  const [myMessage,setMessage]=useState('')
  
  const [MultipleName1,setMultipleName]=useState('')
  const [photo1,setPhoto]=useState('')
  const [mobile1,setMobile]=useState('')
  const [email1,setEmail]=useState('')
  const [position1,setPosition]=useState('')
  const [district1,setDistrict]=useState('')
 
  const inMultiple ={
    mobile:'',
    email:'',
    district:'',
    position:'',
    name:''
  }

  const [multipleUpdate,setMultipleUpdate] = useState(inMultiple)
  const {mobile,email,district,position,name}=multipleUpdate

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);

  const [id, setId] = useState('')

  const handleShow1 = (id) =>{
    setId(id)
    axios.post('https://lions320capi.emedha.in/multiple/update/get',{
        id
    }).then((res)=>{
        setMultipleUpdate({...res.data[0]})
        setShow1(true)
    })    
  }  

  const [search,setSearch]=useState()

  const multiplePost=(e)=>{
    
    e.preventDefault();
    const formData = new FormData();
  
    formData.append('email',email1)
    formData.append('name',MultipleName1)
    formData.append('mobile',mobile1)
    formData.append('photo',photo1)
    formData.append('district',district1)
    formData.append('position',position1)
  
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
  
    axios.post("https://lions320capi.emedha.in/multiple/post",formData,config)
    .then((response) => {
      alert(response.data);
      setTimeout(()=>setShow(false),200)
      MultipleData()
        }).catch((error) => {
          console.log(error)
      });
  
  }

  const [updatePhoto,setUpdatePhoto]= useState()

  const multipleUpdateData=()=>{

    const formData = new FormData();
  
    formData.append('email',email)
    formData.append('name',name)
    formData.append('mobile',mobile)
    formData.append('photo',updatePhoto)
    formData.append('district',district)
    formData.append('position',position)
    formData.append('id',id)
  
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
  
    axios.post("https://lions320capi.emedha.in/multiple/update/post",formData,config)
    .then((response) => {
      alert('Data updated successfully..!');
      setTimeout(()=>setShow1(false),200)
      MultipleData()
        }).catch((error) => {
      });
  
  }
  


  const deleteData=(id)=>{
    if(window.confirm('Are you sure to delete this data...')){
      axios.post(`https://lions320capi.emedha.in/multiple/delete`,{
        id
      })
      alert('Data deleted successfully!...')
      setTimeout(()=>MultipleData(),100)
    }
  }



  const searchHandler=(e)=>{
     setSearch(e.target.value)

     axios.post('https://lions320capi.emedha.in/search',{
      search
     }).then((resp)=>{
      setMultiple(resp.data)
     })
  }

  const onInputChange=(e)=>{
    setMultipleUpdate({...multipleUpdate,[e.target.name]:e.target.value})
  }

  return (

    <>
    <Container fluid><h5 className='text-center text-danger'>{myMessage}</h5></Container>
    <Button variant="warning" onClick={handleShow}>
        Add<PersonAddAltIcon></PersonAddAltIcon>
    </Button>
    <Modal show={show} onHide={handleClose} centered>
       <Modal.Header closeButton>
         <Modal.Title>Add</Modal.Title>
      </Modal.Header>
       <Modal.Body>
         <form onSubmit={multiplePost}>
           
           <input type='text'  className='form-control' name="name" placeholder='Enter Name' required onChange={(e)=>setMultipleName(e.target.value)}/>
           <br/>
           <input type='text'  className='form-control' name="mobile" placeholder='Mobile Number'   onChange={(e)=>setMobile(e.target.value)}/>
           <br/>
           <input type='text' className='form-control' name="email" placeholder='Email'   onChange={(e)=>setEmail(e.target.value)}/>
           <br/>
           <input type='text'   className='form-control' name="position" placeholder='Position'   onChange={(e)=>setPosition(e.target.value)}/>
           <br/>
           <input type='file' className='form-control' placeholder='Photo' name='photo'  onChange={(e)=>setPhoto(e.target.files[0])}/>
           <br/>
           <input type='text' className='form-control' name="district" placeholder='District'     onChange={(e)=>setDistrict(e.target.value)}/>
           <br/>
           <button type='submit' className='btn btn-danger form-control'>Submit</button>
           <br/>
           <button type='reset' className='btn btn-secondary form-control'>Reset</button>
         </form>
       </Modal.Body>
       <Modal.Footer>
         <Button variant="secondary" onClick={handleClose}>
           Close
         </Button>
       </Modal.Footer>
  </Modal>
  <FaSearch className='searchIcon'></FaSearch><input type='search' className='ms-4 search-input-field' placeholder='Search..' onChange={searchHandler}/>
  <div style={{marginTop:'1rem'}} className='table-container'>
       <table className="styled-table table  table-striped table-responsive table-bordered">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Photo</th>
            <th>Position</th>
            <th>District</th>
            <th>Update</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody className='table-bordered'>
          {
            Multiple.slice(pagination.start,pagination.end).map((data,index)=>{
              return(
                 
                    <tr>
                        <td>{index+1}</td>
                        <td>{data.name}</td>
                        <td>{data.email}</td>
                        <td>{data.mobile}</td>
                        <img src={`https://lions320capi.emedha.in/images/${data.photo}`} height='70px' width='70px' style={{marginTop:'-0.4rem'}}/>
                        <td>{data.position}</td> 
                        <td>{data.district}</td>
                        <td className=''>
                            <button  className='btn btn-info' onClick={()=>handleShow1(data.id)}>update</button>
                        </td>
                        <td className=''>
                            <Link to='#' className='btn btn-danger' onClick={()=>deleteData(data.id)}>delete</Link>
                        </td>
                        <Modal show={show1} onHide={handleClose1} centered>
                           <Modal.Header closeButton>
                                <Modal.Title>Add</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form onSubmit={(e)=>{
                                    e.preventDefault()
                                    multipleUpdateData(data.id)
                                }}>
                                <input type='text' name="name" value={name} className='form-control' placeholder='Enter Name' required onChange={onInputChange}/>
                                <br/>
                                <input type='text' name="mobile" value={mobile} className='form-control' placeholder='Mobile Number'   onChange={onInputChange}/>
                                <br/>
                                <input type='text' name="email" value={email} className='form-control' placeholder='Email'   onChange={onInputChange}/>
                                <br/>
                                <input type='text' name="position" value={position} className='form-control' placeholder='Position'   onChange={onInputChange}/>
                                <br/>
                                <input type='file' className='form-control' placeholder='Photo' name='photo'  onChange={(e)=>setUpdatePhoto(e.target.files[0])}/>
                                <br/>
                                <input type='text' name='district' value={district} className='form-control' placeholder='District'   onChange={onInputChange}/>
                                <br/>
                                <button type='submit' className='btn btn-danger form-control'>Update</button>
                                <br/>
                                <button type='reset' className='btn btn-secondary form-control'>Reset</button>
                                </form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose1}>
                                Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </tr>
                 
              )
            })
          }
        </tbody>
       </table>
      <Pagination page={showPerPage} onChangePagination={onChangePagination} total={Multiple.length}/>      
  </div>
</>
  )
}

export default Multiple