import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Pagination from '../pagination/Pagination'
import { Link } from 'react-router-dom'


const Calender = () => {

  const [calendar,setCalendar]=useState([])
  const[showPerPage,setShowPerPage]=useState(10)
  const [pagination,setPagination]=useState(
    {
      start:0,
      end:showPerPage
    }
  )
 
  const onChangePagination=(start,end)=>{
    setPagination({start:start,end:end})
  }

  const calendarData= async ()=>{
    const res=await axios.get(`https://lions320capi.emedha.in/calendar`)
    setCalendar(res.data)
    console.log(res.data)
  }

  useEffect(() => {
    calendarData()
  }, []);

  
  const deleteData=(id)=>{
    if(window.confirm('Are you sure to delete this data...')){
      axios.delete(`https://lions320capi.emedha.in/deleteCalendar/${id}`)
      alert('Data deleted successfully!...')
      setTimeout(()=>calendarData(),100)
    }
  }

  return (
  <div style={{marginTop:'1rem'}} className='table-container'>
       <table className="styled-table table table-bordered">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Membership_Number</th>
            <th>Membership_Name</th>
            <th>Club_Name</th>
            <th>Designation</th>
            <th>Designation</th>
            <th>Update</th>
            <th>Delete</th>
          </tr>
          
        </thead>
        <tbody className='table-bordered'>
          {
            calendar.slice(pagination.start,pagination.end).map((data,index)=>{
              return(
                <tr>
                  <td>{index+1}</td>
                  <td>{data.membershipNo}</td>
                  <td>{data.membershipName}</td>
                  <td>{data.clubs}</td>
                  <td>{data.designation}</td>
                  <td>{data.designation}</td>
                  
                  <td className=''>
                    <Link to='#' className='btn btn-info'>update</Link>
                  </td>
                  <td className=''>
                    <Link to='#' className='btn btn-danger' onClick={()=>deleteData(data.membershipNo)}>delete</Link>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
       </table>
      <Pagination page={showPerPage} onChangePagination={onChangePagination} total={calendar.length}/>      
  </div>
  )
}

export default Calender