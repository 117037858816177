import React, { useState, useEffect } from 'react'
import Pagination from '../pagination/Pagination';
import axios from 'axios'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

// Import Material Icons



const Cabinet = () => {

  const [showMember, setShowMember] = useState(false)


  const [mobile, setMobile] = useState('')
  const [memberName, setMemberName] = useState('--- Select Membership Name ---')
  const [clubName, setClubName] = useState('')
  const [designation, setDesignation] = useState('')




  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false)
    setShowMember(false)
    setMemberName('--- Select Membership Name ---')
    setClubName('')
  };
  const handleShow = () => setShow(true);

  const [cabinet, setCabinet] = useState([])
  const [showPerPage, setShowPerPage] = useState(10)
  const [pagination, setPagination] = useState(
    {
      start: 0,
      end: showPerPage
    }
  )

  const onChangePagination = (start, end) => {
    setPagination({ start: start, end: end })
  }

  const cabinetData = async () => {
    const res = await axios.get(`https://lions320capi.emedha.in/cabinetMaster/get`)
    setCabinet(res.data)
  }

  useEffect(() => {
    cabinetData()
    getCabinateDesignationa()
    getClubName()
  }, []);

  const [query, setQuery] = useState([])


  const submitData = (e) => {
    e.preventDefault();
    axios.post('https://lions320capi.emedha.in/cabinet/post', {
      memberName,
      clubName,
      designation,
      mobile
    }).then(() => {
      alert('Data Successfully Inserted!')
      setShow(false)
      setShowMember(false)
      setMemberName('--- Select Membership Name ---')
      setClubName('')
      setTimeout(() => cabinetData(), 100)
    }).catch((err) => alert(err.res.data))

  }

  const deleteData = (id) => {
    if (window.confirm('Are you sure to delete this data...')) {
      axios.post(`https://lions320capi.emedha.in/cabinet/delete`, {
        id
      })
      alert('Data deleted successfully!...')
      setTimeout(() => cabinetData(), 100)
      cabinetData()
    }
  }

  const search = () => {

  }

  const [getDesignation, setGetDesignation] = useState([])

  const getCabinateDesignationa = async () => {
    const res = await axios.get('https://lions320capi.emedha.in/cabinet/get')
    setGetDesignation(res.data)
  }

  const [clubSelectName, setClubSelectName] = useState([])

  const getClubName = async () => {
    const res = await axios.get('https://lions320capi.emedha.in/clubName/get')
    setClubSelectName(res.data)
  }

  const [membershipSelectName, setMembershipSelectName] = useState([])


  const membershipName =  (clubName) => {
    setClubName(clubName)
    axios.post('https://lions320capi.emedha.in/membershipName/get', {
      clubName
    }).then((res) => {
      setMembershipSelectName(res.data)
    })
  }

  return (


    <>
      <Button variant="warning" onClick={handleShow}>
        Add<PersonAddAltIcon></PersonAddAltIcon>
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitData}>
            <select type='text' className='form-control' placeholder='Designation' onChange={(e) => membershipName(e.target.value)}>
              <option>
                -------Select ClubName----
              </option>
              {
                clubSelectName.map((data) => {
                  return (

                    <option value={data.clubName}>
                      {data.clubName}
                    </option>

                  )
                })
              }
            </select>
            <br />
            {
              clubName !== "" && (
                <div>
                  <input readOnly style={{ cursor:'default',height: '2.3rem', width: '100%', border: '1px solid #d3d3d3', paddingLeft: '0.8rem' ,outline:'none',borderRadius:'0.4rem'}} value={memberName} onClick={() => setShowMember(!showMember)} ></input>
                  {
                    showMember && (
                      <div style={{ height: '23rem', backgroundColor: 'white', overflowY: 'scroll', marginTop: '0.3rem' }} >
                        {
                          membershipSelectName.map((data) => {
                            return (
                              <p className='mem' style={{color:'black'}} onClick={() => {
                                setMobile(data.mobile)
                                setMemberName(data.membershipName)
                                setShowMember(false)
                              }}>{data.membershipName}</p>
                            )
                          })
                        }
                      </div>
                    )
                  }
                </div>
              )
            }
            <br />
            {
              memberName !== "--- Select Membership Name ---" && (
                <select type='text' className='form-control' placeholder='Designation' onChange={(e) => setDesignation(e.target.value)}>
                  <option>
                    -------Select Designation----
                  </option>
                  {
                    getDesignation.map((data) => {
                      return (

                        <option value={data.designation}>
                          {data.designation}
                        </option>

                      )
                    })
                  }
                </select>
              )
            }
            <br />

            <button type='submit' className='btn btn-danger form-control'>Submit</button>
            <br />
            <br />
            <button type='reset' className='btn btn-secondary form-control'>Reset</button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      
      <div style={{ marginTop: '1rem' }} className='table-container'>
        <table className="styled-table table table-bordered">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Membership Name</th>
              <th>Club Name</th>
              <th>Designation</th>
              <th>Mobile</th>
              <th>Delete</th>
            </tr>

          </thead>
          <tbody className='table-bordered'>
            {
              cabinet.slice(pagination.start, pagination.end).map((data, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>

                    <td>{data.membershipName}</td>
                    <td>{data.clubName}</td>
                    <td>{data.designation}</td>
                    <td>{data.mobile}</td>
                    <td className=''>
                      <Link to='#' className='btn btn-danger' onClick={() => deleteData(data.id)}>delete</Link>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Pagination page={showPerPage} onChangePagination={onChangePagination} total={cabinet.length} />
      </div>
    </>
  )
}

export default Cabinet