
import { Link, Navigate } from 'react-router-dom'
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';

const CarnivalMenuEvents = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [title, setTitle] = useState('')
    const [body, setBody] = useState('')

    const run = () => {
        axios.post("https://lions320dapi.emedha.in/run/get", {
            title,
            body
        }).then((res) => {
            if (res.data === 'ok') {
                alert('Notification Successfully send')
                handleClose()
            }
        })
    }



    return (
        <div className='mt-5 mb-5 pt-3 pb-3' style={{ borderRadius: 6 }}>
            <div className='text-center container d-flex d-md-flex justify-content-around align-items-center btnContainer p-5'>
                <div>
                    <Link to={`/carnival-register-member/${'Member'}`}><button className='btnStyle1 shadow'><strong> Member</strong></button></Link>
                    <br />
                    <br />
                    <br />
                    <Link to={`/carnival-register-member/${'None-Member'}`}> <button className='shadow  btnStyle1'><strong>  Non Member</strong></button></Link>
                    <br />
                    <br />
                    <br />
                    <Link to={`/carnival-register-member/${'Sponsorship'}`} > <button className='shadow  btnStyle1'><strong>Sponsership</strong></button></Link>
                </div>

                <div>
                    <Link to={`/carnival-register-member/${'Stall'}`}><button className='shadow  btnStyle1'><strong>  Stall Details</strong></button></Link>
                    <br />
                    <br />
                    <br />
                    <Link to='/stall-layout'> <button className='shadow  btnStyle1'><strong>  Stall Layout</strong></button></Link>
                    <br />
                    <br />
                    <br />
                    <Link to='/update-carnival-data'><button className='shadow  btnStyle1'><strong>Update</strong></button></Link>
                </div>
            </div>

            <>
                <Modal show={show} onHide={handleClose} size='sm' centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Send Notification</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input style={{ backgroundColor: 'white', height: '2.6rem', width: '100%', borderRadius: '0.5rem', marginBottom: 10, outlineColor: 'orange' }} type="text" className='inputText' placeholder='Enter notification title' onChange={(e) => setTitle(e.target.value)} />
                        <input style={{ backgroundColor: 'white', height: '2.6rem', width: '100%', borderRadius: '0.5rem', marginBottom: 10, outlineColor: 'orange' }} type="text" className='inputText' placeholder='Enter notification Body' onChange={(e) => setBody(e.target.value)} />
                        <button onClick={() => run()} className='notificationButton' style={{ marginTop: "0.4rem", height: '2.6rem', width: '5rem', borderRadius: '0.5rem', backgroundColor: 'orange', border: 'none', fontWeight: 'bold', color: 'white' }}>Send</button>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            </>
        </div>
    )
}

export default CarnivalMenuEvents